import React from 'react';
import { graphql } from 'gatsby';

import Wrapper from '../components/Global/Wrapper';
import Page from '../components/Page';

const SinglePage = ({ data }) => {
  const { sanityPage } = data;
  return (
    <Wrapper title={sanityPage.title} seoSettings={sanityPage.seoSettings}>
      <Page {...sanityPage} />
    </Wrapper>
  );
};

export const PageQuery = graphql`
  query pageQuery($id: String!) {
    sanityPage(id: { eq: $id }) {
      id
      _rawBody(resolveReferences: { maxDepth: 10 })
      title
      mainImage {
        asset {
          fixed {
            src
          }
        }
      }
      seoSettings {
        metaDescription
        pageTitle
      }
    }
  }
`;

export default SinglePage;
