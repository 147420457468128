import React from 'react';
import styled from 'styled-components';

import PageTitle from './PageTitle';
import { PageMain } from './layout';
import Content from './Content';

const Page = ({ _rawBody, title, mainImage }) => {
  return (
    <PageWrapper>
      <PageTitle headerImage={mainImage ? mainImage.asset.fixed.src : null} className="page-title">
        {title}
      </PageTitle>
      {_rawBody && (
        <PageMain>
          <Content>{_rawBody}</Content>
        </PageMain>
      )}
    </PageWrapper>
  );
};

const PageWrapper = styled.div``;

export default Page;